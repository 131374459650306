import React, { FC, useEffect, useState } from 'react';
import {
  IconButton,
  MenuItem,
  Tooltip,
  Switch,
  Box,
  Menu,
  Button,
  Select,
} from '@material-ui/core';
import { Check, Close, GetApp, Publish } from '@material-ui/icons';
import { Jira, Play, More } from '~/assets/svg';
import {
  DropDown,
  Header,
  If,
  Menu as Sidebar,
  Modal,
  CardAccordion,
} from '~/components';
import {
  AlignFields,
  Background,
  Board,
  ButtonBox,
  ButtonsContainer,
  CardItemsSpace,
  CustomTextField,
  GeneratedItemsSpace,
  MoreButtonOnCard,
  MoreButtonOnProject,
  Panel,
  SectionFields,
  StyledButton,
  StyledMenu,
  StyledMenuItem,
  StyledReactLoading,
  TextEmpty,
  Title,
  ToggleContent,
  ToggleText,
  VerticalDivisor,
  WrapperFields,
} from '~/styles';
import { colors } from '~/theme';
import { observer, useFormikContext, useStores, alert, REACT_APP_HEALTH_BEAT_API_URL, REACT_APP_ENV } from '~/utils';
import { CustomFormControl, CustomInputLabel } from './styles';
import { Request } from '~/services';
import { getTenantIdCookie } from '~/utils/tenants';
import ReactLoading from 'react-loading';

type Props = {};

const Story: FC<Props> = (): JSX.Element => {
  const { values, handleChange, isSubmitting, submitForm, setFieldValue } =
    useFormikContext<StoryType.Forms>();
  const { epic, story, project, itemId } = useStores();
  const [autoAmount, setAutoAmount] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [inputNewProject, setInputNewProject] = useState('');
  const [selectedStoryId, setSelectedStoryId] = useState<null | number>(null);
  const [anchorElProjectsList, setAnchorElProjectsList] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [anchorElExport, setAnchorElExport] = useState<null | HTMLElement>(
    null,
  );
  const [anchorElImport, setAnchorElImport] = useState<null | HTMLElement>(
    null,
  );
  const [anchorElCard, setAnchorElCard] = useState<null | HTMLElement>(null);
  const [anchorElProject, setAnchorElProject] = useState<null | HTMLElement>(
    null,
  );
  const [editCard, setEditCard] = useState(false);
  const [editedStory, setEditedStory] = useState<any>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProjectName, setEditedProjectName] = useState(
    project.selected?.name || '',
  );
  const [loadingJira, setLoadingJira] = useState(false);

  useEffect(() => {
    project.getJiraProjects();
    project.orderProjects();
    // if (REACT_APP_ENV === 'PRD') {
    //   const healthCheck = async () => {
    //     try {
    //       const tenantId = getTenantIdCookie();
    //       const response = await Request(REACT_APP_HEALTH_BEAT_API_URL || "")
    //         .get(`/heart-beat/${tenantId}`, {
    //           headers: {
    //             'app': 'StoryGen',
    //           },
    //         });
    //     } catch (error) {
    //       console.log('Erro ao tentar fazer health check:', error);
    //     }
    //   };

    //   const interval = setInterval(() => {
    //     if (document.visibilityState === 'visible') {
    //       healthCheck();
    //     }
    //   }, 5000);
    //   return () => clearInterval(interval);
    // }
  }, []);

  useEffect(() => {
    epic.getJiraEpics();
  }, [project.selected]);

  const hasGeneratedItemsForSelectedProject = () => {
    return (
      story.generatedItems.length > 0 &&
      story.generatedItems.some(
        (item) => item.project.id === project.selected?.id,
      )
    );
  };

  const hasLikedStoriesForSelectedProject = () => {
    return story.items.some(
      (item) => item.feedback === 2 && item.project.id === project.selected?.id,
    );
  };

  const defineAmount = () => {
    if (!autoAmount) {
      values.amount = 1;
      alert({
        message: 'A quantidade de histórias geradas será definida manualmente',
        type: 'info',
        position: 'top-center',
      });
    } else {
      values.amount = 0;
      alert({
        message:
          'A quantidade de histórias geradas será definida automaticamente',
        type: 'info',
        position: 'top-center',
      });
    }
    setAutoAmount(!autoAmount);
  };

  const renderProjectName = (item: Project.Type) => {
    if (item.type === 'jira') {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Jira color="#fff" size={16} style={{ marginRight: '8px' }} />
          {item.name}
        </div>
      );
    } else {
      return item.name;
    }
  };


  const handleConfirmNewProject = () => {
    if (inputNewProject.trim()) {
      let newId = itemId.generate();
      project.add({ id: newId, name: inputNewProject, type: 'custom' });
      setInputNewProject('');
      setOpenModal(false);
      project.select(newId);
    }
  };

  const handleSaveClick = () => {
    if (project.selected) {
      if(!editedProjectName.trim()) {
        alert({ message: 'Nome do projeto não pode ser vazio', type: 'warning', position: 'top-center' });
      }else{
        project.selected.name = editedProjectName;
      }
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setEditedProjectName(project.selected?.name || '');
    setIsEditing(false);
  };

  useEffect(() => {
    const handleKeyDown = (event: { key: string }) => {
      if (isEditing) {
        if (event.key === 'Enter') {
          handleSaveClick();
        } else if (event.key === 'Escape') {
          handleCancelClick();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isEditing, handleSaveClick, handleCancelClick]);

  const handleEditStory = (item: Story.Type) => {
    setEditedStory(item);
    setEditCard(true);
  };

  const handleUpdateStory = async () => {
    if (!editedStory?.content?.summary || !editedStory?.content?.description) {
      alert({
        message: 'O título e a descrição da história são obrigatórios.',
        type: 'error',
        position: 'top-center',
      });
      return;
    }

    const payload = {
      id: editedStory.id,
      artifact_id: editedStory.artifact_id,
      item_id: editedStory.item_id,
      content: {
        summary: editedStory.content.summary,
        description: editedStory.content.description,
      },
      feedback: editedStory.feedback, // Inclua o feedback, se necessário
      project: editedStory.project, // Certifique-se de que o projeto está correto
    };

    story.edit(payload);

    try {
      await story.updateStory(payload); // Atualiza a história com o payload completo
      setEditCard(false); // Fecha o modal de edição
    } catch (error) {
      console.error('Erro ao tentar salvar a história:', error);
    }
  };

  return (
    <>
      <Header title="StoryGen" />
      <Sidebar />
      <Background>
        <Panel>
          <DropDown
            type="project"
            onClick={() => {
              setFieldValue('epic', '');
              setFieldValue('description', '');
              setFieldValue('business_rule', '');
              setFieldValue('amount', 0);
            }}
          />
          <DropDown
            type="epic"
            onClick={() => {
              setFieldValue('epic', epic.selected?.content.summary || '');
              setFieldValue('description', epic.selected?.content.description || '');
              setFieldValue('business_rule', '');
              setFieldValue('amount', 0);
            }}
          />
          {/* Modal para edição de história */}
          <Modal
            title="Editar História"
            open={editCard}
            onClose={() => {
              setEditCard(false);
              setAnchorElCard(null);
            }}
            width={600}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '8px 0',
              }}
            >
              <CustomTextField
                label="Título da História"
                placeholder="Digite o título da história"
                variant="outlined"
                size="small"
                value={editedStory?.content?.summary || ''}
                onChange={(event) =>
                  setEditedStory({
                    ...editedStory,
                    content: {
                      ...editedStory.content,
                      summary: event.target.value,
                    },
                  })
                }
                style={{ marginBottom: '20px' }}
                inputProps={{ maxLength: 255 }}
              />

              <CustomTextField
                label="Descrição"
                placeholder="Digite a descrição da história"
                variant="outlined"
                size="small"
                multiline
                value={editedStory?.content?.description || ''}
                onChange={(event) =>
                  setEditedStory({
                    ...editedStory,
                    content: {
                      ...editedStory.content,
                      description: event.target.value,
                    },
                  })
                }
                style={{ marginBottom: '20px' }}
                inputProps={{ maxLength: 1000 }}
              />
              <div
                style={{
                  marginTop: '8px',
                  textAlign: 'right',
                  fontSize: '12px',
                  color: 'gray',
                }}
              >
                {editedStory?.content?.description?.length || 0}/1000 caracteres
              </div>
              <Button
                sx={{
                  backgroundColor: '#E25614',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#FD784E',
                  },
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  handleUpdateStory();
                  setEditCard(false);
                  setAnchorElCard(null);
                }}
                style={{ marginTop: '16px' }}
              >
                Confirmar
              </Button>
            </div>
          </Modal>
          <div
            style={{
              width: '85%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '16px',
            }}
          >

            <StyledButton
              startIcon={<GetApp />}
              variant="contained"
              size="small"
              disabled={!hasLikedStoriesForSelectedProject()}
              style={{ marginBottom: '16px' }}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                setAnchorElExport(event.currentTarget)
              }
            >
              Exportar
            </StyledButton>
            <Menu
              open={Boolean(anchorElExport)}
              onClose={() => setAnchorElExport(null)}
              anchorEl={anchorElExport}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              style={{ marginTop: '4px' }}
              PaperProps={{
                style: {
                  width: anchorElExport ? anchorElExport.clientWidth : 'auto',
                  minWidth: '100px',
                },
              }}
            >
              <MenuItem
                style={{ justifyContent: 'center' }}
                onClick={() => story.exportItemsToTxt()}
              >
                TXT
              </MenuItem>
              <MenuItem
                style={{ justifyContent: 'center' }}
                onClick={() => story.exportToPDF()}
              >
                PDF
              </MenuItem>
              {project.selected?.type === 'jira' && (
                <MenuItem
                  style={{ justifyContent: 'center' }}
                  onClick={() => story.exportToJira()}
                >
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                  {story.isExportingToJira ? (
                    <ReactLoading type="spin" color="#000000DE" height={16} width={16} />
                  ) : (
                    <>
                    <Jira
                      color="#000000DE"
                      size={16}
                      style={{ marginRight: '8px' }}
                    />
                    Jira
                    </>
                  )}
                  </span>
                </MenuItem>
              )}
            </Menu>
            <CardItemsSpace items={hasLikedStoriesForSelectedProject()}>
              {!hasLikedStoriesForSelectedProject() ? (
                <TextEmpty style={{ paddingBottom: '16px' }}>
                  As histórias escolhidas aparecerão aqui
                </TextEmpty>
              ) : (
                <>
                  {story.items
                    .filter((item: any) =>
                      project.selected
                        ? item.project.id === project.selected.id
                        : '',
                    )
                    .filter((item) => item.feedback === 2)
                    .map((item: any) => (
                      <Box
                        key={item.id}
                        sx={{
                          width: '100%',
                          borderRadius: 1,
                          marginBottom: '16px',
                          bgcolor: '#43688B',
                          textAlign: 'center',
                          padding: '24px 16px 16px 16px',
                          cursor: 'pointer',
                          color: 'white',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '16px',
                            fontWeight: '600',
                          }}
                        >
                          {item.content.summary}
                        </p>
                        <small
                          style={{
                            display: '-webkit-box',
                            width: '100%',
                            fontSize: '12px',
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 4,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            marginTop: '8px',
                          }}
                        >
                          {item.content.description}
                        </small>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            textAlign: 'right',
                            position: 'relative',
                          }}
                        >
                          <MoreButtonOnCard
                            onClick={(
                              event: React.MouseEvent<HTMLButtonElement>,
                            ) => {
                              setAnchorElCard(event.currentTarget);
                              setSelectedStoryId(item.id);
                            }}
                          >
                            <More />
                          </MoreButtonOnCard>
                          <Menu
                            open={
                              Boolean(anchorElCard) &&
                              selectedStoryId === item.id
                            }
                            onClose={() => {
                              setAnchorElCard(null);
                              setSelectedStoryId(null);
                            }}
                            anchorEl={anchorElCard}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                            }}
                            style={{ marginTop: '4px' }}
                            PaperProps={{
                              style: {
                                width: anchorElCard
                                  ? anchorElCard.clientWidth
                                  : 'auto',
                                minWidth: '100px',
                              },
                            }}
                          >
                            <MenuItem
                              style={{ justifyContent: 'center' }}
                              onClick={() => handleEditStory(item)}
                            >
                              Editar
                            </MenuItem>
                            <MenuItem
                              style={{ justifyContent: 'center' }}
                              onClick={() => {
                                story.setFeedback(item.id, 3);
                                setAnchorElCard(null);
                              }}
                            >
                              Excluir
                            </MenuItem>
                          </Menu>
                        </div>
                      </Box>
                    ))}
                </>
              )}
            </CardItemsSpace>
          </div>
        </Panel>
        <VerticalDivisor />
        <Board>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Title>
                {isEditing ? (
                  <CustomTextField
                    value={editedProjectName}
                    focused
                    label="Nome do Projeto"
                    placeholder="Digite o nome do projeto"
                    variant="outlined"
                    size="small"
                    onChange={(e) => setEditedProjectName(e.target.value)}
                    inputProps={{ maxLength: 255 }}
                  />
                ) : (
                  <span style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                    {project.selected?.name}
                  </span>
                )}
                {!isEditing && (
                  <span
                    onClick={() => setIsEditing(true)}
                    style={{ marginLeft: 8 }}
                  ></span>
                )}
                {isEditing && (
                  <>
                    <IconButton onClick={handleSaveClick}>
                      <Check style={{ color: 'white' }} />
                    </IconButton>
                    <IconButton onClick={handleCancelClick}>
                      <Close style={{ color: 'white' }} />
                    </IconButton>
                  </>
                )}
              </Title>
              <div style={{ display: 'inline-block', position: 'relative' }}>
                {project.selected && project.selected.type !== 'jira' && (
                  <>
                    <MoreButtonOnProject
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                        setAnchorElProject(event.currentTarget)
                      }
                    >
                      <More />
                    </MoreButtonOnProject>
                    <Menu
                      open={Boolean(anchorElProject)}
                      onClose={() => setAnchorElProject(null)}
                      anchorEl={anchorElProject}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      style={{ marginTop: '4px' }}
                      PaperProps={{
                        style: {
                          width: anchorElProject
                            ? anchorElProject.clientWidth
                            : 'auto',
                          minWidth: '100px',
                        },
                      }}
                    >
                      <MenuItem
                        style={{ justifyContent: ' center' }}
                        onClick={() => {
                          setIsEditing(true);
                          setAnchorElProject(null);
                          setEditedProjectName(project.selected?.name || '');
                        }}
                      >
                        Editar
                      </MenuItem>
                      <MenuItem
                        style={{ justifyContent: 'center' }}
                        onClick={() => {
                          project.remove(project.selected!.id);
                          setAnchorElProject(null);
                        }}
                      >
                        Excluir
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            </div>
            {project.selected && (
              <SectionFields>
                <AlignFields>
                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Épico *"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="epic"
                      onChange={handleChange('epic')}
                      disabled={isSubmitting}
                      value={values.epic}
                      inputProps={{ maxLength: 255 }}
                    />
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Descrição *"
                      variant="outlined"
                      sx={{ m: 1 }}
                      name="description"
                      disabled={isSubmitting}
                      onChange={handleChange('description')}
                      value={values.description}
                    />
                  </WrapperFields>

                  <WrapperFields>
                    <CustomTextField
                      size="small"
                      fullWidth
                      id="outlined-basic"
                      label="Regras de Negócio"
                      variant="outlined"
                      sx={{ m: 1 }}
                      multiline
                      minRows={5}
                      name="business_rule"
                      onChange={handleChange('business_rule')}
                      disabled={isSubmitting}
                      value={values.business_rule}
                      inputProps={{
                        style: {
                          maxHeight: '300px',
                          overflow: 'auto',
                          scrollbarWidth: 'thin',
                        },
                      }}
                    />

                    <CustomFormControl
                      fullWidth
                      variant="outlined"
                      sx={{ m: 1 }}
                      disabled={isSubmitting}
                      size="small"
                    >
                      <CustomInputLabel id="demo-simple-select-label">
                        Severidade
                      </CustomInputLabel>
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="severity"
                        onChange={handleChange}
                        value={values.severity}
                      >
                        <MenuItem value={'baixa'}>Baixa</MenuItem>
                        <MenuItem value={'media'}>Média</MenuItem>
                        <MenuItem value={'alta'}>Alta</MenuItem>
                        <MenuItem value={'critica'}>Crítica</MenuItem>
                      </Select>
                    </CustomFormControl>
                  </WrapperFields>
                  <div style={{ display: 'flex', flex: 1 }}>
                    <ToggleContent onClick={defineAmount}>
                      <Switch
                        checked={autoAmount}
                        disabled={isSubmitting}
                        sx={{
                          '&.MuiSwitch-root .MuiSwitch-switchBase': {
                            color: '#183E62',
                          },
                          '&.MuiSwitch-root .MuiSwitch-switchBase + .MuiSwitch-track':
                          {
                            backgroundColor: '#183E62',
                          },
                          '&.MuiSwitch-root .Mui-checked': {
                            color: '#FD784E',
                          },
                          '&.MuiSwitch-root .Mui-checked + .MuiSwitch-track': {
                            backgroundColor: '#FD784E',
                          },
                        }}
                        name="autoAmount"
                      />
                      <ToggleText>
                        Definir manualmente a quantidade de histórias
                      </ToggleText>
                    </ToggleContent>
                    <If condition={autoAmount}>
                      <CustomTextField
                        size="small"
                        style={{ width: '15%' }}
                        id="outlined-basic"
                        label="Quantidade de histórias"
                        variant="outlined"
                        type="number"
                        sx={{ m: 1 }}
                        name="amount"
                        disabled={isSubmitting}
                        value={values.amount}
                        InputProps={{
                          inputProps: { min: 1, max: 50, step: 1 },
                        }}
                        onChange={({ target }) => {
                          const value = target.value;

                          if (value === "") {
                            setFieldValue('amount', "");
                            return;
                          }
                      
                          const numericValue = +value;
                          if (numericValue < 1 || numericValue > 50) {
                            return;
                          }
            
                          setFieldValue('amount', numericValue);
                        }}
                      />
                      {values.amount < 1 && (
                        <div style={{ color: 'white', margin: '8px' }}>
                          A quantidade deve ser pelo menos 1.
                        </div>
                      )}
                    </If>
                  </div>
                </AlignFields>
                <ButtonsContainer>
                  <Tooltip title="Gerar História" placement="left">
                    <ButtonBox>
                      <If condition={!isSubmitting}>
                        <Play
                          onPress={submitForm}
                          color={colors.primary.main}
                        />
                      </If>
                      <If condition={isSubmitting}>
                        <StyledReactLoading
                          type="bubbles"
                          color={colors.primary.main}
                        />
                      </If>
                    </ButtonBox>
                  </Tooltip>
                </ButtonsContainer>
              </SectionFields>
            )}
          </div>
          {!project.selected ? (
            <TextEmpty style={{ margin: '8px 0' }}>
              Nenhum projeto selecionado até o momento.
            </TextEmpty>
          ) : (
            <GeneratedItemsSpace items={hasGeneratedItemsForSelectedProject()}>
              {!hasGeneratedItemsForSelectedProject() ? (
                <TextEmpty>Nenhuma história gerada até o momento.</TextEmpty>
              ) : (
                <>
                  {story.generatedItems
                    .filter((item) => item.project.id === project.selected?.id)
                    .map((item, index: number) => (
                      <CardAccordion
                        key={index}
                        title={item.content.summary}
                        content={
                          item.content.description
                            ? item.content.description
                            : ''
                        }
                        feedback={item.feedback}
                        onFeedbackChange={(newFeedback) => {
                          story.setFeedback(item.id, newFeedback);
                        }}
                      />
                    ))}
                </>
              )}
            </GeneratedItemsSpace>
          )}
        </Board>
      </Background>
    </>
  );
};

export default observer(Story);
